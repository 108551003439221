<template>
    <section class="trending-block">
        <div v-if="props.t.title">
            <h3 class="multi-block-group-title">
                {{ props.t.title }}
            </h3>
        </div>
        <p
            v-if="props.t.subhead.length > 0"
            class="trending-block-subhead"
        >
            {{ props.t.subhead }}
            <a
                :href="props.subheadLink"
                data-ga-category="Homepage"
                data-ga-action="trending_in_class View more videos"
                data-ga4-event-name="homepage"
                data-ga4-action="trending_in_class View more videos"
            >
                {{ props.t.subheadLinkText }}
            </a>
        </p>
        <div class="trending-block-row">
            <div
                v-for="(trending, key) in trendingList"
                id="trending-block-item"
                :key="key"
                class="trending-block-column"
            >
                <Modal
                    :show="trending.show"
                    mode="youtube"
                    :no-padding="true"
                    @closed="() => trending.show = false"
                >
                    <iframe
                        width="100%"
                        height="100%"
                        :src="`//www.youtube.com/embed/${trending.youtubeId}?rel=0`"
                        frameborder="0"
                        allowfullscreen
                    />
                </Modal>
                <a
                    :style="{backgroundImage: `url(${trending.backgroundImageLink})`}"
                    data-ga-category="Homepage"
                    :data-ga-action="trending.gaAction"
                    data-ga4-event-name-category="homepage"
                    :data-ga4-action="trending.gaAction"
                    :aria-live="trending.youtubeId ? 'polite' : undefined"
                    :tabindex="trending.youtubeId ? '0' : undefined"
                    :aria-label="trending.youtubeId ? 'play video' : undefined"
                    :title="trending.title"
                    @click.prevent="() => onBlockClick(trending)"
                >
                    <span class="play" />
                    <span class="trending-title">
                        {{ trending.title }}
                        <em v-if="trending.subtext.length > 0">{{ trending.subtext }}</em>
                    </span>
                </a>
            </div>
        </div>
        <slot />
    </section>
</template>

<script setup lang="ts">
export interface TrendingListItem {
    backgroundImageLink: string;
    title: string;
    url?: string,
    subtext: string;
    youtubeId: string;
    show: boolean,
    gaAction: string
}

import { reactive } from 'vue';
import Modal from '@components/Core/Modal.vue';

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            title: '',
            subhead: '',
            subheadLinkText: ''
        })
    },
    trendings: {
        type: Array<TrendingListItem>,
        default: () => []
    },
    subheadLink: {
        type: String,
        default: ''
    }
})

const trendingList = reactive(props.trendings.map((trending) => ({
    ...trending,
    show: false
})))
const onBlockClick = (trending: TrendingListItem) => {
    if (trending.url) {
        window.location.href = trending.url
        return
    }

    trending.show = true
}
</script>

<style scoped>
.trending-block * {
    font-family: var(--zumba-font-default);
}

.trending-block .multi-block-group-title,
.trending-block .trending-block-subhead,
.trending-block a .trending-title {
    font-weight: 700;
}

.trending-block {
    background-color: #F5F5F5;
    padding: 3.875em 0;
    padding-top: 2rem;
    margin-bottom: 3.75em;
}

.trending-block .multi-block-group-title {
    font-size: 2.25rem;
    color: #1d1d24;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    padding: 0 1.25em;
}

.trending-block .trending-block-subhead {
    text-align: center;
    max-width: 58em;
    margin: 0 auto;
    font-size: 1.125rem;
    margin-bottom: 1em;
}

.trending-block .trending-block-subhead a {
    width: 100%;
    height: auto;
    display: inline;
    color: #d43361;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    text-decoration: none;
}

.trending-block .trending-block-row {
    max-width: 76.875rem;
    margin: 0 auto;
    padding: 0 1.5em
}

.trending-block .trending-block-column {
    width: 100%;
}

.trending-block a {
    height: 313px;
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.trending-block .play {
    display: block;
    margin: 1.08em auto 0 auto;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    box-shadow: none;
    background: none;
    cursor: pointer;
    opacity: 1;
    border: 2px solid #fff;
    pointer-events: auto !important;
    z-index: 2;
    position: relative;
}

.trending-block .play:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: #00000000 #00000000 #00000000 var(--zumba-white);
    position: relative;
    top: 50%;
    left: 39%;
    transform: translateY(-50%);
}

.trending-block a .trending-title {
    color: var(--zumba-white);
    line-height: 1.6;
    padding: 1.5em 2em;
    text-transform: uppercase;
    background: #2c2d37cc;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.trending-block a .trending-title em {
    display: block;
    font-style: normal;
    text-transform: none;
    font-weight: 300;
}
@media only screen and (max-width: 47.9375em) {
    .trending-block a {
        margin-bottom: 1.5rem;
        height: 216px;
    }

    .trending-block a .trending-title {
        padding: 1em;
    }
}
@media only screen and (min-width: 48em) {
    .trending-block .trending-block-row {
        display: flex;
        padding: 0 1em;
        max-width: 66.25rem;
    }

    .trending-block .trending-block-column {
        width: 50%;
    }
    .trending-block .play {
        float: right;
        width: 2.5em;
        height: 2.5em;
        margin: 1.08em 1.08em 0 0;
    }

    .trending-block .play:before {
        border-width: 6.5px 0 6.5px 11.3px;
    }

    .trending-block .trending-block-iframe {
        width: 44rem;
        height: 24.5rem;
    }
}

@media only screen and (min-width: 64rem) {
    .trending-block {
        background-color: #F5F5F5;
    }

    .trending-block .trending-block-row {
        padding: 0;
        gap: 1.25rem;
    }
    .trending-block a .trending-title {
        width: 100%;
        box-sizing: border-box;
    }

    .trending-block .trending-block-iframe {
        width: 59rem;
        height: 33rem;
    }
}
@media (max-width: 48em) {
    .trending-block{
        padding-bottom: 1.5rem;
        margin-bottom: 3rem;
    } 
}

.swap-section-styles .trending-block {
    padding-top: 3.75rem;
}

.swap-section-styles .transformation-trending-section .trending-block {
    background-color: var(--zumba-white);
    padding: 0;
}
</style>
